<template>
  <ValidationObserver ref="validationObserver" v-slot="{ pristine, valid }">
    <div class="settings container">
      <ContentBlock class="form">
        <div slot="instructions">
          <h5>{{ $t("Contact Information") }}</h5>
          <p>{{ $t('Enter your contact information here.') }}</p>
        </div>
        <div slot="content">
          <div class="userinfos">
            <UIInput maxlength="255" class="input" :title="$t('Your Name')" v-model="user.name" required rules="required|max:255|alphaSpacesNumericRich" vid="userName" />
            <UIInput
              maxlength="255"
              class="input"
              :title="$t('Email')"
              v-model="user.email"
              :instructions="$t('The email address used to log into your account.')"
              required
              rules="required|max:255|email"
            />
          </div>
        </div>
      </ContentBlock>
      <OrganizationDetailsForm
        :organization="organization"
        :countries="countries"
        :regions="regions"
        :loading="organizationLoading"
        @edit:organization="editOrganization"
      />
      <PasswordChange :ignoreValidationForParent="true" />
      <BottomBar @save="saveUser" @cancel="$router.go(-1)" :saving="saving" :valid="!pristine && valid"/>
    </div>
  </ValidationObserver>
</template>

<script>
import Component from 'vue-class-component';
import UserSettings from '@/core/shared/auth/UserSettingsRoute';
import OrganizationDetailsForm from './OrganizationDetailsForm';
import { isEqual, isEmpty } from 'lodash-es';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    OrganizationDetailsForm,
    ValidationObserver,
  },
})
export default class ContentOnlyUserSettings extends UserSettings {
  organizationChanges = {};

  get organization () {
    return { ...this.organizationInStore, ...this.organizationChanges };
  }

  get organizationInStore () {
    return this.$store.getters.currentOrganization;
  }

  editOrganization (property, value) {
    const dirty = !isEqual(this.organizationInStore[property], value);
    if (dirty) this.$set(this.organizationChanges, property, value);
    else this.$delete(this.organizationChanges, property);
  }

  get countries () {
    return this.$store.getters['entities/Country/all']();
  }

  get regions () {
    return this.$store.getters['entities/Region/all']();
  }

  get organizationLoading () {
    return this.$store.state.organization.isFetchingOrganization;
  }

  async saveUser () {
    this.saving = true;
    try {
      const { isValid } = await this.$refs.validationObserver.validateWithInfo();
      if (!isValid) return;

      if (!isEmpty(this.userChanges)) {
        await this.$store.dispatch('saveUser', { id: this.user.id, ...this.userChanges });
      }
      if (!isEmpty(this.organizationChanges)) {
        await this.$store.dispatch('storeOrganization', { id: this.organization.id, ...this.organizationChanges });
      }
      this.$store.commit('setFlash', {
        type: 'success',
        message: this.savedMsg,
      });
      this.$router.go(-1);
    } finally {
      this.saving = false;
    }
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.settings {
  padding-top: var(--spacingMd);

  & .userinfos {
    display: grid;
    grid-gap: var(--spacingSm);

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
