<template>
  <ContentBlock class="form" :loading="loading" required>
    <div slot="instructions">
      <h5>{{ $t('Organization Details') }}</h5>
      <p>{{ $t("Enter your organization's information here.") }}</p>
    </div>
    <div slot="content">
      <div class="formgrid">
        <div class="basics">
          <UIInput :title="$t('Organization Name')" v-model="localOrganization.name" required rules="required|alphaSpacesNumericRich" vid="orgName" />
          <UIInput :title="$t('Website')" v-model="localOrganization.website" vid="orgWebsite" />
        </div>
        <div class="contact">
          <UIInput :title="$t('Email')" v-model="localOrganization.contactEmail" rules="email" vid="orgEmail" />
          <UIInput :title="$t('Phone')" v-model="localOrganization.contactPhone" rules="phone" vid="orgPhone" />
        </div>
        <AddressInputs
          v-if="countries && fullAddress.address"
          :countries="countries"
          :regions="regions"
          :fullAddress="fullAddress"
          @update:fullAddress="onUpdateFullAddress"
        />
        <div class="vat">
          <UIInput class="tax" :title="$t(inEU ? 'VAT Number' : 'Tax Number')" v-model="localOrganization.taxNumber" :required="inEU && !localOrganization.vatNumberExempt" :disabled="localOrganization.vatNumberExempt" :rules="{ 'required': inEU && !localOrganization.vatNumberExempt }" vid="orgTaxNumber" />
        </div>
        <div>
          <UISwitch
            v-if="inEU"
            :title="$t('VAT number exemption')"
            v-model="localOrganization.vatNumberExempt"
            @change="removeTaxNumber"
            :instructions="$t('By selecting this option, you are informing us that you are not legally required to have a VAT number and understand that we, being an EU based company, are required to charge VAT on our invoices.')"
          />
        </div>
      </div>
    </div>

  </ContentBlock>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UISelect from '@/core/shared/components/ui/UISelect';
import AddressInputs from '@/core/shared/components/AddressInputs';

@Component({
  components: {
    ContentBlock,
    UIInput,
    UISwitch,
    UISelect,
    AddressInputs,
  },
  props: {
    countries: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
  },
  computed: {
    inEU: function () {
      const euCountries = ['BE', 'EL', 'LT', 'PT', 'BG', 'ES', 'LU', 'RO', 'CZ', 'FR', 'HU', 'SI', 'DK', 'HR', 'MT', 'SK', 'DE', 'IT', 'NL', 'FI', 'EE', 'CY', 'AT', 'SE', 'IE', 'LV', 'PL'];
      return euCountries.indexOf(this.localOrganization.country) !== -1;
    },
  },
})
export default class OrganizationDetailsForm extends Vue {
  get localOrganization () {
    return new Proxy(this.organization, {
      set: (object, property, value) => {
        this.$emit('edit:organization', property, value);
        return true;
      },
    });
  }

  get fullAddress () {
    const {
      address,
      address2,
      city,
      state,
      postalCode,
      country,
    } = this.localOrganization;
    return {
      address,
      address2,
      city,
      state,
      postalCode,
      country,
    };
  }

  onUpdateFullAddress ({ address, address2, city, state, postalCode }) {
    this.localOrganization.address = address;
    this.localOrganization.address2 = address2;
    this.localOrganization.city = city;
    this.localOrganization.state = state;
    this.localOrganization.postalCode = postalCode;
  }

  removeTaxNumber () {
    this.localOrganization.taxNumber = null;
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.formgrid {
  display: grid;
  grid-gap: var(--spacingSm);

  & .basics,
  & .contact,
  & .vat {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
