<template>
  <div class="address-inputs">
    <UIInput
      class="span-3"
      :title="$t('Address')"
      :value="localFullAddress.address"
      @input="updateFullAddress('address', $event)"
      required
      rules='required'
    />
    <UIInput
      class="span-3"
      :title="$t('Address 2')"
      :value="localFullAddress.address2"
      @input="updateFullAddress('address2', $event)"
    />
    <UIInput
      class="span-2"
      :title="$t('City')"
      :value="localFullAddress.city"
      @input="updateFullAddress('city', $event)"
      required
      rules='required|alphaSpaces'
    />
    <UISelect
      class="span-2"
      :title="$t('State')"
      :value="localFullAddress.state"
      @change="updateFullAddress('state', $event)"
      :disabled="!regions.some(x => x.countryCode === localFullAddress.country)"
    >
      <option v-for="region in regions.filter(x => x.countryCode === localFullAddress.country)" :key="region.isoRegionCode" :value="region.isoRegionCode">{{ region.name }}</option>
    </UISelect>
    <UIInput
      class="span-2"
      :title="$t('Postal Code / ZIP')"
      :value="localFullAddress.postalCode"
      @input="updateFullAddress('postalCode', $event)"
      required
      rules='required|alphaNumericSpaces'
    />
    <UISelect
      class="span-6"
      :title="$t('Country')"
      :value="localFullAddress.country"
      @change="updateFullAddress('country', $event)"
      :instructions="(disableCountrySelect) ? $t('Contact Support to Modify Country.') : null"
      :disabled="disableCountrySelect"
    >
      <option v-for="country in countries" :key="country.cca3" :value="country.cca3">{{ country.name }}</option>
    </UISelect>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api';
import UIInput from './ui/UIInput';
import UISelect from './ui/UISelect';

export default {
  name: 'AddressInputs',
  props: {
    fullAddress: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
  },
  components: {
    UIInput,
    UISelect,
  },
  setup (props, { emit }) {
    const localFullAddress = reactive(props.fullAddress);

    function updateFullAddress (type, event) {
      localFullAddress[type] = event.target.value;
      emit('update:fullAddress', localFullAddress);
    }

    const disableCountrySelect = props.fullAddress.country.length > 0;

    return {
      localFullAddress,
      updateFullAddress,
      disableCountrySelect,
    };
  },
};
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.address-inputs {
  display: grid;
  grid-gap: var(--spacingMd);

  @media (--tablet) {
    grid-template-columns: repeat(6, 1fr);

    & .span-2 {
      grid-column: span 2;
    }

    & .span-3 {
      grid-column: span 3;
    }

    & .span-6 {
      grid-column: span 6;
    }
  }
}
</style>
